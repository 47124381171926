[class^="button-"] {
    padding-top: 12px;
    padding-bottom: 12px;
    border-radius: 28px;
    outline: none;
    border: none;
    font-weight: 500;
    font-size: 14px;
    @media only screen and (max-width: 800px) {
        padding-top: 8px;
        padding-bottom: 8px;
    }
}

.button-18 { // button with padding 18px
    padding-left: 18px;
    padding-right: 18px;
}
.button-30 { // button with padding 18px
    padding-left: 30px;
    padding-right: 30px;
}