$primary-color: #326291;
$secondary-color: #FED66B;
$third-color: #000;
$third-color-80: rgba(0, 0, 0, .80);

// fonts
.font-constantia {
    font-family: Constantia;
}
.font-poppins {
    font-family: "Poppins", sans-serif;
}

.font-bold {
    font-weight: bold;
}
.font-semi-bold {
    font-weight: 600;
}
.font-italic {
    font-style: italic;
}

.font-size-1 {
    font-size: 22px;
}
.font-size-2 {
    font-size: 18px;
}
.font-size-3 {
    font-size: 14px;
}
.font-size-sm {
    font-size: 12px;
}

.font-color-white {
    color: white;
}
.font-color-1 {
    color: $primary-color !important;
}
.font-color-2 {
    color: $secondary-color !important;
}
.font-color-gray {
    color: #797979;
}

.eight-cm-text {
    width: 300px !important;
    @media only screen and (max-width: 800px) {
        width: 100% !important;
    }
}

// border
.border-1 {
    border-width: 1;
}
.border-2 {
    border-width: 2;
}
.border-color-gray {
    border-color: #f7f5f5;
}
.border-color-dark-gray {
    border-color: rgba(74, 74, 74, .5);
}
.border-solid {
    border-style: solid;
}
.border-radius-1 {
    border-radius: 16px;
}
.border-radius-2 {
    border-radius: 8px;
}
.border-radius-50 {
    border-radius: 50%;
}
.border-radius-lg {
    border-radius: 32px;
}

.border-color-ocean-blue {
    border-color: #4cadcd !important;
}

// backgrounds
.bgr-color-1 {
    background-color: $primary-color;
}
.bgr-color-2 {
    background-color: $secondary-color;
}
.bgr-white {
    background-color: white;
}

//text-color

.color-black{
    color: $third-color;
}
.color-black-80{
    color: $third-color-80;
}

// utilities
.p1 {
    padding: 8px;
}
.p2 {
    padding: 16px;
    @media only screen and (max-width: 375px) {
        padding: 10px;
    }
}
.pt2 {
    padding-top: 16px;
}
.pb2 {
    padding-bottom: 16px;
}
.pl2 {
    padding-left: 16px;
}
.pr2 {
    padding-right: 16px;
}
.p3 {
    padding: 24px;
}
.pt3 {
    padding-top: 24px;
}
.pb3 {
    padding-bottom: 24px;
}
.pl3 {
    padding-left: 24px;
}
.pr3 {
    padding-right: 24px;
}
.p4 {
    padding: 32px;
}
.pl4 {
    padding-left: 32px;
}
.pr4 {
    padding-right: 32px;
}
.m1 {
    margin: 8px;
}
.mt1 {
    margin-top: 8px;
}
.mb1 {
    margin-bottom: 8px;
}
.ml1 {
    margin-left: 8px;
}
.mr1 {
    margin-right: 8px;
}
.m2 {
    margin: 16px;
}
.mt2 {
    margin-top: 16px;
}
.mb2 {
    margin-bottom: 16px;
}
.ml2 {
    margin-left: 16px;
}
.mr2 {
    margin-right: 16px;
}
.m3 {
    margin: 24px;
}
.mt3 {
    margin-top: 24px;
}
.mb3 {
    margin-bottom: 24px;
}
.ml3 {
    margin-left: 24px;
}
.mr3 {
    margin-right: 24px;
}
.m4 {
    margin: 32px;
}
.mt4 {
    margin-top: 32px;
}
.mb4 {
    margin-bottom: 32px;
}
.ml4 {
    margin-left: 32px;
}
.mr4 {
    margin-right: 32px;
}

.box-shadow-1 {
    box-shadow: 2px 2px 2px #e2e2e2;
}

// btn
.primary-btn {
    width: 100%;
    max-width: 242px;
    border-radius: 3em;
    background-color: $primary-color;
    @media (max-width: 375px){
        max-width: 200px;
    }
}
@import "./button-styles.scss";

// dialog
.full-width-dialog {
    width: 100vw !important;
    height: 100vh !important;
    padding: 0 !important;
    margin: 0 !important;
    // display: block !important;
}
.dialog-container .mat-dialog-container {
    @media only screen and (max-width: 1000px) {
        width: 100vw !important;
        height: 100vh !important;
        max-height: 100vh !important;
        padding: 0 !important;
        margin: 0 !important;
    }
}
.dialog-test-border-radius .mat-dialog-container {
    border-radius: 80px !important;
}
.dialog-test-container .mat-dialog-container {
    width: 100% !important;
    max-width: 850px;
    height: auto !important;
    max-height: 90vh;
    margin: 24px auto !important;
    @media only screen and (max-width: 1000px) {
        border-radius: 0px !important;
        display: block !important;
        max-height: 100vh;
        margin: 0 auto !important;
    }
}
// .cdk-overlay-pane 
::ng-deep .cdk-global-overlay-wrapper {
    max-width: 100vw !important;
    width: 100% !important;
    display: block !important;
    margin-left: 0 !important;
    padding-left: 0 !important;
    @media only screen and (max-width: 1000px) {
        max-height: 100vh !important;
    }
 }
// ::ng-deep .mat-dialog-container {
//     overflow-y: hidden !important;
// }