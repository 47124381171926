@import "./variables";

$interval: 2;

@for $i from 0 through 150 {
    $space: $i * $interval;

    // Margin
    .mr-#{$space}px { margin-right: #{$space}px; }
    .mr-#{$space}px-imp { margin-right: #{$space}px !important; }

    .ml-#{$space}px { margin-left: #{$space}px; }
    .ml-#{$space}px-imp { margin-left: #{$space}px !important; }

    .mt-#{$space}px { margin-top: #{$space}px; }
    .mt-#{$space}px-imp { margin-top: #{$space}px !important; }

    .mb-#{$space}px { margin-bottom: #{$space}px; }
    .mb-#{$space}px-imp { margin-bottom: #{$space}px !important; }
    
    // Padding
    .pr-#{$space}px { padding-right: #{$space}px; }
    .pr-#{$space}px-imp { padding-right: #{$space}px !important; }

    .pl-#{$space}px { padding-left: #{$space}px; }
    .pl-#{$space}px-imp { padding-left: #{$space}px !important; }

    .pt-#{$space}px { padding-top: #{$space}px; }
    .pt-#{$space}px-imp { padding-top: #{$space}px !important; }

    .pb-#{$space}px { padding-bottom: #{$space}px; }
    .pb-#{$space}px-imp { padding-bottom: #{$space}px !important; }

    .px-#{$space}px { 
        padding-left: #{$space}px;
        padding-right: #{$space}px;
    }
    .px-#{$space}px-imp {
        padding-left: #{$space}px !important;
        padding-right: #{$space}px !important;
    }

    .py-#{$space}px {
        padding-top: #{$space}px;
        padding-bottom: #{$space}px;
    }
    .py-#{$space}px-imp {
        padding-top: #{$space}px !important;
        padding-bottom: #{$space}px !important;
    }
}

@for $i from 0 through 150 {
    $space: $i * $interval;
    $screen: 'lg';

    @media (max-width: #{$breakpoint-lg}px) {
        // Margin
        .mr-#{$screen}-#{$space}px { margin-right: #{$space}px; }
        .mr-#{$screen}-#{$space}px-imp { margin-right: #{$space}px !important; }

        .ml-#{$screen}-#{$space}px { margin-left: #{$space}px; }
        .ml-#{$screen}-#{$space}px-imp { margin-left: #{$space}px !important; }

        .mt-#{$screen}-#{$space}px { margin-top: #{$space}px; }
        .mt-#{$screen}-#{$space}px-imp { margin-top: #{$space}px !important; }

        .mb-#{$screen}-#{$space}px { margin-bottom: #{$space}px; }
        .mb-#{$screen}-#{$space}px-imp { margin-bottom: #{$space}px !important; }
        
        // Padding
        .pr-#{$screen}-#{$space}px { padding-right: #{$space}px; }
        .pr-#{$screen}-#{$space}px-imp { padding-right: #{$space}px !important; }

        .pl-#{$screen}-#{$space}px { padding-left: #{$space}px; }
        .pl-#{$screen}-#{$space}px-imp { padding-left: #{$space}px !important; }

        .pt-#{$screen}-#{$space}px { padding-top: #{$space}px; }
        .pt-#{$screen}-#{$space}px-imp { padding-top: #{$space}px !important; }

        .pb-#{$screen}-#{$space}px { padding-bottom: #{$space}px; }
        .pb-#{$screen}-#{$space}px-imp { padding-bottom: #{$space}px !important; }

        .px-#{$screen}-#{$space}px { 
            padding-left: #{$space}px;
            padding-right: #{$space}px;
        }
        .px-#{$screen}-#{$space}px-imp {
            padding-left: #{$space}px !important;
            padding-right: #{$space}px !important;
        }

        .py-#{$screen}-#{$space}px {
            padding-top: #{$space}px;
            padding-bottom: #{$space}px;
        }
        .py-#{$screen}-#{$space}px-imp {
            padding-top: #{$space}px !important;
            padding-bottom: #{$space}px !important;
        }
    }
}

@for $i from 0 through 150 {
    $space: $i * $interval;
    $screen: 'md';

    @media (max-width: #{$breakpoint-md}px) {
        // Margin
        .mr-#{$screen}-#{$space}px { margin-right: #{$space}px; }
        .mr-#{$screen}-#{$space}px-imp { margin-right: #{$space}px !important; }

        .ml-#{$screen}-#{$space}px { margin-left: #{$space}px; }
        .ml-#{$screen}-#{$space}px-imp { margin-left: #{$space}px !important; }

        .mt-#{$screen}-#{$space}px { margin-top: #{$space}px; }
        .mt-#{$screen}-#{$space}px-imp { margin-top: #{$space}px !important; }

        .mb-#{$screen}-#{$space}px { margin-bottom: #{$space}px; }
        .mb-#{$screen}-#{$space}px-imp { margin-bottom: #{$space}px !important; }
        
        // Padding
        .pr-#{$screen}-#{$space}px { padding-right: #{$space}px; }
        .pr-#{$screen}-#{$space}px-imp { padding-right: #{$space}px !important; }

        .pl-#{$screen}-#{$space}px { padding-left: #{$space}px; }
        .pl-#{$screen}-#{$space}px-imp { padding-left: #{$space}px !important; }

        .pt-#{$screen}-#{$space}px { padding-top: #{$space}px; }
        .pt-#{$screen}-#{$space}px-imp { padding-top: #{$space}px !important; }

        .pb-#{$screen}-#{$space}px { padding-bottom: #{$space}px; }
        .pb-#{$screen}-#{$space}px-imp { padding-bottom: #{$space}px !important; }

        .px-#{$screen}-#{$space}px { 
            padding-left: #{$space}px;
            padding-right: #{$space}px;
        }
        .px-#{$screen}-#{$space}px-imp {
            padding-left: #{$space}px !important;
            padding-right: #{$space}px !important;
        }

        .py-#{$screen}-#{$space}px {
            padding-top: #{$space}px;
            padding-bottom: #{$space}px;
        }
        .py-#{$screen}-#{$space}px-imp {
            padding-top: #{$space}px !important;
            padding-bottom: #{$space}px !important;
        }
    }
}

@for $i from 0 through 150 {
    $space: $i * $interval;

    @media (max-width: #{$breakpoint-sm}px) {
        // Margin
        .mr-sm-#{$space}px { margin-right: #{$space}px; }
        .mr-sm-#{$space}px-imp { margin-right: #{$space}px !important; }

        .ml-sm-#{$space}px { margin-left: #{$space}px; }
        .ml-sm-#{$space}px-imp { margin-left: #{$space}px !important; }

        .mt-sm-#{$space}px { margin-top: #{$space}px; }
        .mt-sm-#{$space}px-imp { margin-top: #{$space}px !important; }

        .mb-sm-#{$space}px { margin-bottom: #{$space}px; }
        .mb-sm-#{$space}px-imp { margin-bottom: #{$space}px !important; }
        
        // Padding
        .pr-sm-#{$space}px { padding-right: #{$space}px; }
        .pr-sm-#{$space}px-imp { padding-right: #{$space}px !important; }

        .pl-sm-#{$space}px { padding-left: #{$space}px; }
        .pl-sm-#{$space}px-imp { padding-left: #{$space}px !important; }

        .pt-sm-#{$space}px { padding-top: #{$space}px; }
        .pt-sm-#{$space}px-imp { padding-top: #{$space}px !important; }

        .pb-sm-#{$space}px { padding-bottom: #{$space}px; }
        .pb-sm-#{$space}px-imp { padding-bottom: #{$space}px !important; }

        .px-sm-#{$space}px { 
            padding-left: #{$space}px;
            padding-right: #{$space}px;
        }
        .px-sm-#{$space}px-imp {
            padding-left: #{$space}px !important;
            padding-right: #{$space}px !important;
        }

        .py-sm-#{$space}px {
            padding-top: #{$space}px;
            padding-bottom: #{$space}px;
        }
        .py-sm-#{$space}px-imp {
            padding-top: #{$space}px !important;
            padding-bottom: #{$space}px !important;
        }
    }
}

// font size
@for $i from 0 through 100 {
    $size: $i * $interval;

    // Font Size
    .fs-#{$size} { font-size: #{$size}px; }
    .fs-#{$size}-imp { font-size: #{$size}px !important; }

    // Line height
    .lh-#{$size} { line-height: #{$size}px; }
    .lh-#{$size}-imp { line-height: #{$size}px !important; }
}

@for $i from 0 through 100 {
    $size: $i * $interval;

    @media (max-width: #{$breakpoint-sm}px) {
        .fs-sm-#{$size} { font-size: #{$size}px; }
        .fs-sm-#{$size}-imp { font-size: #{$size}px !important; }

        .lh-sm-#{$size} { line-height: #{$size}px; }
        .lh-sm-#{$size}-imp { line-height: #{$size}px !important; }
    }
}

@for $i from 0 through 100 {
    $size: $i * $interval;

    @media (max-width: #{$breakpoint-xs}px) {
        .fs-xs-#{$size} { font-size: #{$size}px; }
        .fs-xs-#{$size}-imp { font-size: #{$size}px !important; }

        .lh-xs-#{$size} { line-height: #{$size}px; }
        .lh-xs-#{$size}-imp { line-height: #{$size}px !important; }
    }
}

@for $i from 0 through 30 {
    .br-#{$i}px { border-radius: #{$i}px; }
    .gap-#{$i}px { gap: #{$i}px; }
}

.width-45px { width: 45px; }
.width-45px { width: 45px; }
.width-60px { width: 60px; }
.width-80px {
    flex-grow: 0;
    flex-shrink: 0;
    width: 80px;
    flex-basis: 80px;
}
.width-100px {
    flex-grow: 0;
    flex-shrink: 0;
    width: 100px;
    flex-basis: 100px;
}
.width-200px { width: 200px; }
.width-300px { width: 300px; }
.fill-blue-1 { fill: $blue-1; }

.bg-yellow-1 { background-color: $yellow-1; }
.bg-yellow-2 { background-color: $yellow-2; }
.bg-blue-1 { background-color: $blue-1; }
.bg-blue-2 { background-color: $blue-2; }
.bg-blue-2-imp { background-color: $blue-2 !important; }
.bg-white { background-color: #fff; }
.bg-gray-5 { background-color: $gray-5; }
.bg-gray-5-imp { background-color: $gray-5 !important; }

.color-blue-1 { color: $blue-1; }
.color-blue-1-imp { color: $blue-1 !important; }
.color-blue-2 { color: $blue-2; }
.color-white { color: #ffffff; }
.color-white-imp { color: #ffffff !important; }
.color-gray-2 { color: $gray-2; }
.color-gray-3 { color: $gray-3; }
.color-gray-4 { color: $gray-4; }

.fill-blue-2 { fill: $blue-2; }


.w-fit { width: fit-content; }
.w-fit-imp { width: fit-content !important; }

.gap-20 { gap: 20px; }
.gap-10 { gap: 10px; }

// Typography
.font-constantia { font-family: "Constantia"; }
.font-poppins { font-family: "Poppins", sans-serif; }
.font-calling { font-family: "A Calling"; }

.font-normal { font-weight: normal; }
.font-400 { font-weight: 400; }
.font-500 { font-weight: 500; }
.font-600 { font-weight: 600; }
.font-700 { font-weight: 700; }

.clickable {
    color: $blue-2;
    cursor: pointer;

    &:hover {
        color: $blue-2;
    }
}

.text-link-2 {
    color: $blue-2;
    cursor: pointer;
    text-decoration: underline;
}

.text-underline {
    text-decoration: underline;
}

.border-1px-solid { border: 1px solid; }
.border-blue-2 { border-color: $blue-2; }

.flip-horizontal {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}

.width-fit-content { width: fit-content; }
.width-fit-content-imp { width: fit-content !important; }

.max-width-fit-content { max-width: fit-content; }
.max-width-fit-content-imp { max-width: fit-content !important; }

.z-1 { z-index: 1; }
.z-11 { z-index: 11; }

.paw-start {
    position: relative;
    list-style: none;
    padding-left: 30px;

    &::after {
        content: "";
        height: 20px;
        width: 20px;
        position: absolute;
        left: 0;
        top: 4px;
        background-image: url("/assets/img/ic_paw.svg");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: 50%;
    }
}