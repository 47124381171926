// Break points
$breakpoint-xs: 575.98;
$breakpoint-sm: 767.98;
$breakpoint-md: 991.98;
$breakpoint-lg: 1199.98;
$breakpoint-xl: 1200;

// General
$button-xs-padding: 5px 24px;
$button-xs-font-size: 10px;

$button-md-padding: 10px 24px;